<template>
  <div>
    <div v-if="isLoading"><Loader /></div>
    <div class="app-content-box bg-gray profile-comments" v-else>
      <div class="container wrapper-edit-shelf">
        <h2 class="top-heading-big">
          Edytuj półkę
        </h2>
      </div>
      <ValidationObserver ref="observer" tag="form">
        <form @submit.prevent="">
                <div class="container mt-3">
          <div class="input-container">
            <ValidationProvider rules="required" v-slot="{errors}">
              <input type="text" v-model="shelfTitle" class="form-input w-100" placeholder="nazwa półki" />
              <span class="form-error-info text-center">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
      </div>
      <div class="book-form-container mt-4">
        <div class="form-group form-group-border">
          <div class="container form-container">
            <div class="form-checkbox-switch">
              <input
                type="checkbox"
                name="checkbox-switch_"
                id="checkbox-switch_1"
                v-model="isChecked"
              />
              <label for="checkbox-switch_1">
                publiczna
                <span class="switch-pill">
                  <span class="switch-dot"></span>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group form-group-footer submit-button-bottom">
      <div class="container form-container">
      <button type="submit" @click="submit()" class="btn-black-full w-100 mt-3">Zapisz</button>
      </div>
    </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import Loader from '../components/Loader'

extend('required', {
  ...required,
  message: 'Pole jest obowiązkowe'
})

export default {
  name: 'EditShelf',
  components: {
    ValidationProvider,
    ValidationObserver,
    Loader
  },
  props: {
    id: [String, Number]
  },
  data () {
    return {
      isLoading: true,
      shelfTitle: '',
      isChecked: false,
      allShelves: [],
      editingShelf: []
    }
  },
  async created () {
    this.getAllShelves()
  },
  methods: {
    async getAllShelves () {
      await this.$https('/shelf', {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        }
      })
        .then(res => {
          const items = res.data.response
          items.forEach(e => {
            if (e.id === this.id) {
              this.editingShelf.push(e)
            }
          })
          this.shelfTitle = this.editingShelf[0].name
          this.allShelves = items.map(e => {
            if (e.name !== this.editingShelf[0].name) {
              return e.name
            }
          })
          this.isLoading = false
        })
        .catch(error => {
          console.log('', error)
        })
    },
    async submit () {
      const valid = await this.$refs.observer.validate()
      const isShelfExsist = this.allShelves.includes(this.shelfTitle)
      if (valid === true && isShelfExsist) {
        alert('Posiadasz już półkę o takiej nazwie')
      } else if (this.shelfTitle.trim() === '') {
        alert('Nazwa półki nie może być pusta')
      } else {
        this.saveShelf()
        this.$router.replace({ path: '/biblioteka' })
      }
      this.getAllShelves()
    },
    saveShelf () {
      const checkboxVal = this.isChecked ? 'public' : 'private'
      const getData = `name=${this.shelfTitle}&privacy=${checkboxVal}`
      this.$https('/shelf/' + this.id, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
      })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    height: 100vh;
  }
  .wrapper-edit-shelf {
    padding-top: 30px;
  }
  .submit-button-bottom {
    width: 100%;
    position: absolute;
    bottom: 10%;
    border-top: none;
  }
</style>
